.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  flex-direction: column;
  height: 100%;
}

.bg {
  background-image: linear-gradient(
    310deg,
    #008080 0%,
    #004040 20%,
    #191919 50%,
    #400040 80%,
    #800080 100%
  );
}

.header {
  flex-direction: column;
  position: absolute;
  top: 6rem;
}

h1 {
  display: flex;
  text-align: center;
  font-family: Bruno Ace SC;
  font-size: 48px;
  letter-spacing: 10px;
  margin-bottom: 6rem;
}

h1,
h2 {
  color: #fff;
}

.social-container {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 8rem;
}

.social {
  margin: .5rem 1rem;
  width: 60px;
  height: 60px;
  color: #400040;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 10px;
  font-size: 2rem;
}

.social a {
  display: flex;

}

.social:hover {
  transition: 0.5s ease;
  cursor: pointer;
  animation: wiggle 1s ease;
}

.footer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 2rem;
  color: #cccccc;
  font-weight: 600;
}

.footer p {
  margin-right: 8px;
}

@keyframes wiggle {
  30% {
    transform: scale(1.2);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 24px;
    line-height: 40px;;
  }

  .social-container {
    margin: 0 .5rem;
  }

  .social {
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
    margin: 0.5rem 0.4rem;
  }

}